@charset "utf-8";



// Our variables
$spacing-unit:     10px;
$image-size:       50px;
$icon-size:        15px;


// Sizes
$on-palm:          768px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "styles",
        "highlight"
;
